.presence-container {


    a.btn-primary {
        background-color: #8095AF;
        background-image: none;
        font-size: 18px;
        padding: 4px 12px 4px 12px;
    }

}
