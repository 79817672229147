.childItem {
    .hdr-name {
        font-weight: bold;
        display: inline-block;
        font-size: 18px;
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
    }
}


#confirmation {
    
    #hdr-name {
        font-weight: bold;
        display: block;
        font-size: 18px;
        margin-bottom: 10px;
    }


    hr {
        border-top: 1px solid #D4EDE9;
    }

    #confirm-label {
        font-size: 18px;
        text-align: center;
    }

    #confirm-hour {
        text-align: center;
        font-size: 22px;
        font-weight: bold;
    }
}