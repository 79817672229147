

header {


    #presence {
        

        img {
            height: 44px;
            margin: 4px;
        }
    }

    .navbar {
        width: 100%;
        max-width: 800px;
        display: flex;
        justify-content: space-between;
        margin: auto;
        border: none;
    }
}



#presence-menu {
    margin-left:auto;
    #toMessages {
        display: inline-flex;
        margin-right: 10px;
        background-image: url('/assets/images/ico_info.png');
        background-size: 26px 26px;
        width: 26px;
        height: 26px;
    }
    #toMessages {
        display: inline-flex;
        margin-right: 10px;
        background-image: url('/assets/images/ico_info.png');
        background-size: 26px 26px;
        width: 26px;
        height: 26px;
    }

    .badge {
        position: absolute;
        left: 14px;
        top: 18px;
        background-color: red;
        font-size: 9px;
    }
}

#presence-menu-btn, #presence-menu-btn:hover {
    background-image: url('/assets/images/menuger.png');
    background-size: cover;
    background-origin: content-box;
    width: 33px;
    height: 28px;
    padding: 4px;
    box-shadow: none;
    -webkit-box-shadow: none;
    display: inline-block;
    background-repeat: no-repeat;
    border: none;
}