.privacypolicy {
    margin-top: 30px;

    & li {
        margin-bottom: 16px;
    }

    & ol.b {
        list-style-type: lower-alpha;
    }

    & ol li:first-child {
        margin-top: 16px;
    }
}
