.presence-hdrimage {
    height: 197px;
    width: 100%;
}


#presence-slide-left {
    background-image: url('/assets/images/1px_pink.png');
    background-repeat: repeat-x;
    width: calc(100% - 100px);
    max-width: calc(50% + 300px);
    height: 100%;
    float: left;
    display: inline-block;


    .container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 197px;
        width: 100%;
        max-width: calc(800px - 100px);
        float: right;
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        padding-right: 50px;

        span {
            font-size: 28px;
        }
    }
}

#presence-slide-right {
    background-image: url('/assets/images/1px_celadon.png');
    background-repeat: repeat-x;
    width: 100px;
    min-width: calc(50% - 300px);
    height: 100%;
    float: right;
    display: inline-block;

    img {
        margin-left: -57px;
        margin-top: 22px;
    }
}

