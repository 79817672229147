.presencemsg {
    background-color: #f9f9f9;
    border-radius: 6px 6px 6px 6px;
    -moz-border-radius: 6px 6px 6px 6px;
    -webkit-border-radius: 6px 6px 6px 6px;
    border: 2px solid #c2c2c2;
    margin-bottom: 10px;
    padding: 10px;


    h5 {
        background-color: #e0e0e0;
        width: calc(100% + 20px);
        padding: 10px 10px 10px 10px;
        margin-top: 4px;
        margin-left: -10px;
        margin-right: -10px;
        font-weight: bold;
    }
}
