.dayInfo-wrapper {
    margin-top: 20px;
    background-color: #f9f9f9;

    .day-name {
        text-align: center;
    }

    label {
        display: inline;
        font-size: 17px;
    }

    .checkin, .checkout, .absenece-info {
        padding: 10px;
    }

    .absenece-info {
        font-weight: 700;
    }

    .checkin label, .checkout label {
        width: 100%;
        font-weight: 700;
        padding-right: 20px;
    }
}
