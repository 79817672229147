.presence-error {
    margin-top: 20px;
    background-image: url('/assets/images/error_icon.png');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 40px;
    background-size: 80px;
    border: 1px solid #e2e2e2;
    border-radius: 11px;
    background-color: transparent;
    padding-top: 150px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-box-shadow: 0 0 6px 2px #e2e2e2;
    box-shadow: 0 0 6px 2px #e2e2e2;
    font-weight: bold;
    font-size: 16px;
    text-align: center;

    &.gpsError {
        background-image: url('/assets/images/gps_icon.png');
    }

    &.genError {
        background-image: url('/assets/images/error_icon.png');
    }
}
