.calendar-wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 800px;

    .child-title {
        margin: 20px 0;
        font-size: 17px;
        font-weight: 700;

        span {
            margin-left: 6px;
            font-size: 20px;
        }
    }
}

.calendar {
    width: 100%;
    max-width: 800px;
    background-color: #f9f9f9;

    .calendar-head {
        display: flex;
        justify-content: space-between;

        .month-year {
            line-height: 1.42;
            font-size: 17px;
        }

        .buttons-wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 8px;
            border-bottom: 2px solid #ddd;

            button {
                border: none;
                background: transparent;
            }

            span {
                font-size: 18px;
                font-weight: 700;
                color: #000;
            }

            .glyphicon {
                color: #337ab7;
            }

            .glyphicon:hover {
                cursor: pointer;
                color: #23527c;
            }

            .month-year:hover {
                color: #000;
                cursor: default;
            }
        }

    }
}

.calendar-legend {
    padding: 5px 0 10px 0;

    .dot-container {
        display: flex;
        align-items: center;
    }

    .dot {
        margin: 4px 4px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
    }

    .dot-red {
        background-color: #e80303;
    }

    .dot-green {
        background-color: #429f59;
    }

    .dot-blue {
        background-color: #0744a6;
    }
    .absence {
        margin: 4px 4px;
        width: 24px;
        height: 18px;
        border-radius: 40px;
        border: 1px solid #1c6ea4;
        background: repeating-linear-gradient(135deg, #fcfcfc, #fcfcfc 4px, #C3CDD8 5px, #C3CDD8 8px);
    }
    }

.absence-wrapper {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    font-size: 18px;
    justify-content: space-between;

    .absence-link {
        margin-left: auto;
        color: #000;
        text-decoration: none;
    }

    .absence-img {
        padding: 5px;
        height: 50px
    }

    .absence-span {
        font-weight: 700;
        color: #000;
    }
}



.weekday-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7,1fr);
    grid-auto-rows: auto;
    justify-items: center;
    align-items: center;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    border-bottom: 1px solid #ddd;

    .weekday {
        padding: 8px;
        font-size: 17px;
        color: #333;
    }
}

.weekday-wrapper li:nth-child(7) {
    color: red;
}
