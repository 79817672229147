.days-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7,1fr);
    grid-auto-rows: auto;
    padding-left: 0;
    list-style: none;
    border-bottom: 1px solid #ddd;
}

.day {
    width: 100%;
    padding: 8px;
    justify-self: center;
    font-size: 17px;
    color: #333;
    text-align: center;
    border-bottom: 1px solid #ddd;


    .badge {
        display: inline-block;
        min-width: 10px;
        padding: 1px 4px;
        font-size: 12px;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        background-color: #aaa;
        color: #aaa;
        border-radius: 10px;
    }

    .badge.badge-ok {
        color: #429f59;
        background-color: #429f59;
    }

    .badge.badge-over {
        color: #e80303;
        background-color: #e80303;
    }


    .badge.none {
        color: #fff;
        background-color: #fff;
    }


    .badge.badge-in {
        color: #0744a6;
        background-color: #0744a6;
    }

    .badge.absence {
        color: #101010;
        background-color: #101010;
    }

    .holiday {
        border: 1px solid #cfcfcf;
        border-radius: 40px;
        width: 40px;
        color: #000;
        background-color: #cfcfcf;
        margin: auto;
    }

    div.absence-sel {
        border: 1px solid #1c6ea4;
        border-radius: 40px;
        width: 40px;
        color: #000;
        margin: auto;
    }

    .absence-on {
        border: 1px solid #1C6EA4;
        color: black;
        border-radius: 40px;
        width: 40px;
        font-weight: bold;
        margin: auto;
        background: repeating-linear-gradient( 135deg, #fcfcfc, #fcfcfc 4px, #C3CDD8 5px, #C3CDD8 8px );
    }

    &.selday div.absence-on {
        background: repeating-linear-gradient(135deg,#fcfcfc,#fcfcfc 4px,#46bae1 5px,#46bae1 8px);
        color: black;
    }


    &.selday div {
        background-color: #4691E1;
        color: white;
        display: block;
        border-radius: 40px;
        -moz-border-radius: 40px;
        -webkit-border-radius: 40px;
        padding: 0;
        margin: auto;
        width: 25px;
        font-weight: bold;
    }
}
