.presence-container {

    .form-narrow {
        max-width: 400px;
        margin: auto;
    }

    h4 {
        font-weight: bold;
    }

    button {
        width: 100%;
    }

    button.btn-primary {
        background-color: #8095AF;
        background-image: none;
        font-size: 18px;
        padding: 4px 12px 4px 12px;
    }

    button.btn-success {
        font-size: 18px;
    }
}
