.childItem {

    .delete-menu {
        display: block;
        width: 6px;
        height: 28px;
        margin: auto;
        padding: 0px;
        float: right;
        margin-top: 0px;
        margin-right: calc(50% - 181px);
        background-image: url('/assets/images/threedots.png')
    }

    .name {
        font-size: 20px;
        padding: 10px;
        color: #000;
        display: block;
        text-decoration: none;
    }

    .name:hover {
        text-decoration: none;
    }

    .show-history {
        background-image: url('/assets/images/nab_story_small.png');
        background-size: contain;
        display: block;
        width: 61px;
        height: 61px;
        margin: auto;
        padding: 0;
        float: right;
        margin-top: -171px;
        margin-right: calc(50% - 161px);
    }

    .show-meals {
        background-image: url('/assets/images/lunch.png');
        background-size: contain;
        display: block;
        width: 61px;
        height: 61px;
        margin: auto;
        padding: 0;
        float: right;
        margin-top: -61px;
        margin-right: calc(50% - 161px);
    }


    @media (max-width: 400px) {
        .show-history {
            margin-right: calc(50% - 91px);
        }

        .show-meals {
            margin-right: calc(50% - 91px);
        }
    }


    .child-menu {
        display: block;
        width: 6px;
        height: 28px;
        margin: auto;
        padding: 0;
        float: right;
        margin-top: 0;
        background-image: url('/assets/images/threedots.png');
    }
}
