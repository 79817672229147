#manupage-header2 {
    text-align: center;
    font-size: 24px;
}


#manupage-day {
    margin-top: 20px;
    font-size: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.meal-item {
    width: 100%;
    border: 1px solid #e0e0e0;
    padding: 10px;
    margin: 10px 0 10px 0;

    span {
        font-size: 1.35rem;
		text-transform: capitalize;
    }

    p {
        margin-top: 10px;
    }
}