.childItem {
	text-align: center;
	margin-top: 20px;
	font-size: 16px;

	.person {
		display: block;
		width: 171px;
		height: 171px;
		margin: auto;
		padding: 10px;
	}

	@media (max-width: 400px) {
		.person {
			margin-left: 0;
		}
	}

	.person.female {
		background-image: url('/assets/images/nab_kidgirl_big.png');
		background-size: contain;
	}

	.person.male {
		background-image: url('/assets/images/nab_kidboy_big.png');
		background-size: contain;
	}

	.person.adult {
		background-image: url('/assets/images/adult.png');
		background-size: contain;
	}

	.person.person-small {
		display: inline-block;
		width: 61px;
		height: 61px;
		margin-left: 8px;
		padding: 8px;
		float: left;
	}
}
