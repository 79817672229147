
body {
    padding: 0;
    font-size: 14px;
    font-family: 'Titillium Web',sans-serif;
    color: #333;
    background-color: #fff;
}

div .container{
    width: 100%;
    max-width: 800px;
    margin:0 auto;
}

h3 {
    display: block;
    margin-top: 1rem;
    font-size: 1.7em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 500;
}

.modal-body {
    font-size: 1rem;
}

.btn-primary {
    background-image: linear-gradient(to bottom, #337ab7 0%, #265a88 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff265a88', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    background-repeat: repeat-x;
    border-color: #245580;
}

.error {
    margin-top: 10px;
    color: #c40000;
}

#btn-return {
    background-color: #ebeced;
    font-size: 18px;
    width: 100%;
}

.form-error-summary {
    border: 1px solid #c40000;
    margin-bottom: 20px;
    padding: 10px;
}

.presence-container p a {
    overflow-wrap: anywhere;
}

.footer {
    text-align: right;
    padding: 0 40px;
}