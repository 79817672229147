.absence-wrapper {
    .btn {
        max-width: 180px;
    }
    .button-group {
        display: flex;
        gap: 15px;
    }
}
.calendar-legend {
     margin-top:5px;
    .dot-container {
        display: flex;
        align-items: center;
        .dot-absence {
           margin: 4px 4px;
           width: 34px;
           height: 26px;
           border-radius: 40px;
           border: 1px solid #1c6ea4;
           background: repeating-linear-gradient(135deg, #fcfcfc, #fcfcfc 4px, #C3CDD8 5px, #C3CDD8 8px);
         }
    }
}